
import { defineComponent } from 'vue'
import AdminPanelNavMenu from '@/components/molecules/AdminPanelNavMenu.vue'
import SortArrow from '@/assets/svg/sort-arrow.svg?inline'
import AnaliticMenuSVG from '@/assets/svg/analitic-menu.svg?inline'
import SelectArrow from '@/assets/svg/select-arrow.svg?inline'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import Pagination from '@/components/molecules/Pagination.vue'
import AnalyticsNavMenu from '@/components/molecules/AnalyticsNavMenu.vue'
import Select from '@/components/atoms/CustomSelect.vue'
import Calendar from '@/components/molecules/Calendar.vue'
import vClickOutside from 'click-outside-vue3'

export default defineComponent({
  name: 'StudentsMovedUpToNextLevel',
  data () {
    return {
      page: 1,
      analyticsData: {},
      analyticsMeta: null,
      erroranAlytics: null,
      searchData: '',
      sortData: '',
      isModalDelete: false,
      idInstructor: null,
      dataAllInstructors: [],
      dataLevels: [],
      filters: '',
      filtersLevel: '',
      isMenuActive: false,
      selectedNewDate: null,
      isCalendarVisible: false,
      filtersDate: ''
    }
  },
  components: {
    AdminPanelNavMenu,
    AnalyticsNavMenu,
    SortArrow,
    DefaultPopup,
    AlertBlock,
    Pagination,
    AnaliticMenuSVG,
    Select,
    Calendar,
    SelectArrow
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted () {
    this.getAnalytics()
    this.getLevels()
    this.getInstructors()
  },
  methods: {
    getAnalytics (params, sort) {
      let searchParam = `?page=${this.page}&n=20&search=`
      if (this.searchData !== '') {
        searchParam = `?page=${this.page}&search=` + this.searchData
      }
      if (sort === undefined) {
        sort = 'id'
      }
      params = searchParam + '&sort=' + sort + this.filters + this.filtersLevel + this.filtersDate
      this.$store.dispatch('analytics/studentsMovedUpToNextLevel', params).then(
        (res) => {
          this.analyticsData = res.data
          this.analyticsMeta = res.meta
        },
        (error) => {
          this.erroranalytics = error.response.data.errors
        }
      )
    },
    getInstructors () {
      const params = '?n=100'
      this.$store.dispatch('instructors/getInstructors', params).then(
        (res) => {
          this.dataAllInstructors = res.data
        },
        (error) => {
          this.errorInstructors = error.response.data.errors
        }
      )
    },
    getLevels () {
      this.$store.dispatch('general/getLevels').then(
        (res) => {
          this.dataLevels = res.data
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        }
      )
    },
    handlePage (page) {
      this.page = page
      this.getAnalytics()
    },
    handleOptionSelected (filterKey, selectedOption, callback) {
      const newFilter = `filter[${filterKey}]=${selectedOption.id}`
      const regex = new RegExp(`filter\\[${filterKey}\\]=[^&]*`)
      if (selectedOption.id !== undefined) {
        if (regex.test(this.filters)) {
          this.filters = this.filters.replace(regex, newFilter)
        } else {
          this.filters += `${this.filters.includes('?') ? '&' : '?'}${newFilter}`
        }
      } else this.filters = this.filters.replace(regex, '').replace(/&{2,}/g, '&').replace(/[?&]$/, '')
      if (callback) callback(selectedOption.id)
      this.getAnalytics()
    },
    resetFilters () {
      window.location.reload()
    },
    toggleMenu () {
      this.isMenuActive = !this.isMenuActive
    },
    closeMenu () {
      this.isMenuActive = false
    },
    toggleCalendar () {
      this.isCalendarVisible = !this.isCalendarVisible
    },
    closeCalendar () {
      this.isCalendarVisible = false
    },
    selectDateFill (date, month, year) {
      const selectedDate = new Date(year, month, date.value)
      const currentDate = new Date()
      const Month = selectedDate.getMonth() + 1
      let monthFormat = ''
      if (Month < 10) {
        monthFormat = '0' + (selectedDate.getMonth() + 1)
      } else monthFormat = (selectedDate.getMonth() + 1).toString()
      const Day = selectedDate.getDate()
      let dayFormat = ''
      if (Day < 10) {
        dayFormat = '0' + (selectedDate.getDate())
      } else dayFormat = (selectedDate.getDate()).toString()
      if (selectedDate > currentDate) {
        return
      }
      if (date.value !== '') {
        const selectedDate = new Date(year, month, date.value)
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            '-' +
            monthFormat +
            '-' +
            dayFormat
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
            '-' +
            monthFormat +
            '-' +
            selectedDate.getDate() >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            '-' +
            monthFormat +
            '-' +
            dayFormat
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            '-' +
            monthFormat +
            '-' +
            dayFormat
          this.selectedEndDate = null
        }
      }
      if (this.selectedEndDate) {
        this.filtersDate = `&filter[date_range][0]=${this.selectedStartDate}&filter[date_range][1]=${this.selectedEndDate}`
        this.getAnalytics()
      }
    }
  }
})
